import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from "../components/layout";
import Joe from "../images/Geschäftsführer_Gummelt.jpg";
import SEO from "../components/seo";
import { graphql, useStaticQuery } from "gatsby";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function Contact() {
  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  const data = useStaticQuery(graphql`
  query AnbietenContactQuery {
    site {
      siteMetadata {
        telephone
        serviceMail
      }
    }
  }
`);

  return (

  

    <Layout>
      <section>
        <h1>Immobilie anbieten</h1>
        <h2>Wir bewerten gerne Ihre Immobilien</h2>
        <div className="xl:flex lg:flex md:flex sm:block mb-4 h-auto">

          <div className="sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 pt-4">
            <p><img src={Joe} className="w-6/12" alt="Joe Al Gummelt - Geschäftsführer" /></p><p className="text-xl">Joe Al Gummelt<br /><font className="text-base">Geschäftsführer</font></p>

            <p><b>Kundenanfragen</b><br />Haben Sie Fragen zu unseren Immobilien? Wir stehen Ihnen unter <a href={`mailto:${data.site.siteMetadata.serviceMail}`}>{data.site.siteMetadata.serviceMail}</a> gerne beratend zur Seite.</p></div>
          <div className="sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 pt-4 text-left mx-auto">
            <form
              name="contacttest"
              method="post"
              action="/impress/"
              data-netlify="true"
              onSubmit={handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contacttest" />
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                </label>
              </p>
              <label className="block font-bold mb-2">
                Ihr Name
                   <input
                  className="appearance-none block bg-white mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
                  id="Name"
                  type="text"
                  name="Name"
                  required
                  placeholder=""
                  onChange={handleChange}
                />
              </label>
              <label className="block font-bold mb-2">
                Ihre E-Mail-Adresse
                   <input
                  className="appearance-none block bg-white mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
                  id="E-Mail"
                  type="email"
                  name="E-Mail"
                  required
                  placeholder=""
                  onChange={handleChange}
                />
              </label>
              <label className="block font-bold mb-2">
                Ihre Telefonnummer
                   <input
                  className="appearance-none block bg-white mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
                  id="Telefon"
                  type="tel"
                  name="Telefon"
                  required
                  placeholder=""
                  onChange={handleChange}
                />
              </label>
              <fieldset>
                <legend className="block font-bold mb-2 px-2">Art Ihrer zur Disposition stehenden Immobilie</legend>
                <p>
                  <label>
                    <input type="radio" name="Art" value="Einfamilienhaus" onChange={handleChange} /> Einfamilienhaus
      </label>
                </p>
                <p>
                  <label>
                    <input type="radio" name="Art" value="Eigentumswohnung" onChange={handleChange} /> Eigentumswohnung
      </label>
                </p>
                <p>
                  <label>
                    <input type="radio" name="Art" value="Gewerbeeinheit" onChange={handleChange} /> Gewerbeeinheit
      </label>
                </p>
                <p>
                  <label>
                    <input type="radio" name="Art" value="Mehrparteienhaus" onChange={handleChange} /> Mehrparteienhaus
      </label>
                </p>
                <p>
                  <label>
                    <input type="radio" name="Art" value="Garage" onChange={handleChange} /> Garage
      </label>
                </p>
                <p>
                  <label>
                    <input type="radio" name="Art" value="Stellplatz" onChange={handleChange} /> Stellplatz
      </label>
                </p>
              </fieldset>

              <label className="block font-bold mb-2 pt-2">
                Ihre Nachricht
                   <textarea
                 className="appearance-none bg-white mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
                 id="Nachricht"
                  name="Nachricht"
                  required
                  placeholder="Wie können wir Ihnen am bestem Seite stehen?"
                  onChange={handleChange}
                />
              </label>
              <button type="submit">Senden</button>
              
            </form>

          </div>
        </div>




      </section>
    </Layout>
  )
}